import React from "react";
import { Container, Accordion, AccordionSummary, AccordionDetails,Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
export default class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container id="contact">
        <div>
          <Accordion className="contactTab">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Contactperoon</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
              <li><HomeIcon className="contactListIcon"/>
                Arjan Kok</li>
                <li>Stevensweg 52</li>
                <li>Dordrecht</li>
                <li>Zuid-Holland</li>
                <li>3319AK</li>
                <li>Nederland</li>
                {/* <br/> */}
                <li> <PhoneIcon className="contactListIcon"/>
                +31 (0) 653 50 82 79</li>
                <li> <a href="http://www.kopvlakke.nl">http://www.kopvlakke.nl</a></li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>

        <Accordion className="contactTab">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Overige Gegevens</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <ul>
            <li>
              <BusinessIcon className="contactListIcon"/>
              Kamer van koophandel nr.: <b>73058572</b></li>
              <li>BTW nr.: <b>NL002030693B91</b></li>
              <li>IBAN nr.: <b>NL80 RABO 0110127080</b></li>
            </ul>
          </AccordionDetails>
        </Accordion>
        </div>
      </Container>
    );
  }
}
