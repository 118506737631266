import React from "react";
import "./App.scss";




import Routes from "./routes.jsx";
import { ThemeProvider, createMuiTheme, CssBaseline } from "@material-ui/core";

// Theme
const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#333333",
    }
  },
});

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  render() {
    return (
      <div className="App">
          <ThemeProvider theme={theme}>
            <CssBaseline/>
          
            <Routes /> 
          </ThemeProvider>
      </div>
    );
  }
}

export default App
