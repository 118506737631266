import React from "react";

// import { Link } from 'react-router-dom';
// Components
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container, Card } from "@material-ui/core";

export default class CilinderkopVlakkenTransport extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container id="cilVlakTransport">
        <div id="cilVlakTransportContent">
        {/* <div id="homeContent"> */}
        <h2 >Transport service en tarieven</h2>
        <div id="homeTextblock">
          <img id="cilKopTransportImage" src="/assets/images/icons/transport.png" alt="transport foto"/>
          <p>
            Voor de regio Drechtsteden en Hoekse Waard kan transport voor u verzorgd worden, we 
            beschikken over eigen middelen om uw te repareren of te reviseren onderdeel op te halen en 
            weer bij u terug af te leveren. Tegen een geringe meerprijs worden uw materialen opgehaald, 
            gereviseerd en weer terug gebracht. U heeft dan zich dan niet druk te maken over vervoer 
            van het op te halen onderdeel of motor. Alles wordt voor u geregeld !
          </p>
          <p>
            Natuurlijk is het ook mogelijk om op overige locaties onderdelen of motoren op te halen en weer te bezorgen.
          </p>
          <p>
            Informeert u vrijblijvend naar de mogelijkheden.
          </p>
          <div id="transportCards">
            <Card className="sideCard">
              <div className="sideCardContent">
                <Card className="transportTitleCard" style={{backgroundColor:"#333333", color:"white"}}><h2>Dordrecht</h2></Card>
                <h1 className="transportPrice"><span className="euroSign">€</span>0,00</h1>
                <p>Ophalen</p>
                <p>Bezorgen</p>
                <p>Alleen het eiland</p>
              </div>
            </Card>
            <Card id="midCard" style={{backgroundColor:"#333333", color:"white"}}>
              <div className="midCardContent">
                <Card className="transportTitleCard" ><h2>Hoekse Waard</h2></Card>
                <h1 className="transportPrice"><span className="euroSign">€</span>15,00</h1>
                <p>Ophalen</p>
                <p>Bezorgen</p>
                <p>Excl. tunnelkosten € 8,00</p>
                {/* <div className="transportContactUs">
                  <h2>Neem nu contact op:</h2>
                  <Button>+3160000</Button>
                  <Button>Email@email.com</Button>
                </div> */}
              </div>
            </Card>
            <Card className="sideCard">
              <div className="sideCardContent">
                <Card className="transportTitleCard" style={{backgroundColor:"#333333", color:"white"}}><h2>Drechtsteden</h2></Card>
                <h1 className="transportPrice"><span className="euroSign">€</span>7,50</h1>
                <p>Ophalen</p>
                <p>Bezorgen</p>
                <p>Incl. H.I. Ambacht</p>
              </div>
            </Card>
          </div>
        </div>
        </div>
        {/* </div> */}
      </Container>
    );
  }
}
