import React from "react";
import { Container} from "@material-ui/core";
import productsArray from "../../components/ProductInfo";
import ProductVerzoekComponent from "../../components/ProductVerzoekComponent"
import { Redirect } from "react-router-dom";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      productInfo: productsArray.find(
        (x) => x.productId === props.match.params.id
      ),
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    if (!this.state.productInfo) {
      return <Redirect to="/webshop" />;
    }
    return (
      <Container id="productVerzoek">
          <img src={this.state.productInfo.productImages[0]} alt="" />
          <h1 id="productTitle">{this.state.productInfo.title}</h1>
          <ProductVerzoekComponent prodInfo={this.state.productInfo}/>
      </Container>
    );
  }
}
