import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';


// Import Swiper styles
import 'swiper/swiper.scss';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    SwiperCore.use([Autoplay])
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
        autoplay={{ delay: 2000 }}
      effect="fade"
      loop="true"
      id="swiperComponent"
    >
   
      <SwiperSlide className="swiperSlide" ><img src="/assets/images/005.jpg" alt=""/><p><b>Cilinderkoppen vlakken</b><br />Tot op 1/100 millimeter nauwkeurig</p></SwiperSlide>
      <SwiperSlide className="swiperSlide" ><img src="/assets/images/006.jpg" alt=""/><p><b>Revisies &amp; reparatie</b><br />Alle soorten motoren</p></SwiperSlide>
      <SwiperSlide className="swiperSlide" ><img src="/assets/images/007.jpg" alt=""/><p><b>Onderhoud jachten</b><br />Winterklaar maken & Service</p></SwiperSlide>
      <SwiperSlide className="swiperSlide" ><img src="/assets/images/008.jpg" alt=""/><p><b>Dealer smeermiddelen</b><br />Smeerolie | Vetten | Filters | Accu's</p></SwiperSlide>
    </Swiper>
  );
};