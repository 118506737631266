import React from "react";

import { Link } from 'react-router-dom';
// Components
import ServicesComponent from '../../components/ServicesComponent';
import SwiperComponent from '../../components/SwiperComponent';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container, Button, Card, Divider } from "@material-ui/core";

export default class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container id="home">
        <SwiperComponent/>
        <div id="homeContent">
        <h2>Cilinderkop vlakken, motorrevisie en onderhoud in regio Dordrecht</h2>
        <Card style={{backgroundColor:"#333333", color:"white", padding:"7px"}}><b>Arjan Technische Service, gespecialiseerd in het vlakken van cilinderkoppen, reparatie en motorrevisie.</b></Card>
        <ServicesComponent/>
        <div id="homeTextblock">
          <p>Alle soorten reparaties kunnen worden uitgevoerd aan scheepsmotoren, generatorsets, vrachtwagenmotoren, tractoren, fabrieksmachines en veldgeneratoren. Wij zijn thuis in alle soorten en typen motoren en machines.
          U heeft dus te maken met een veelzijdig (diesel)monteur met vele jaren ervaring.
          Ook kunt u ook terecht voor een complete motorrevisie.</p>
          <p>Reparaties kunnen op locatie uitgevoerd worden en beschikken ook over een moderne werkplaats waar de juiste apparatuur klaarstaat om u zo snel mogelijk weer aan in bedrijf of aan het varen te krijgen.</p>
          <p>Wij beschikken over een uitgebreid netwerk van leveranciers om de juiste originele onderdelen voor onderhoud en service te kunnen leveren. Zo bent u altijd verzekerd van snelle en professionele service. Bijkomende sleutelwerkzaamheden zijn geen probleem, kortom; als het op technische service aankomt bent u bij Arjan Technische Service aan het juiste adres !!</p>
          <p>Ook voor het testen van verstuivers, vervangen van verstuiver norzels en turbo revisie kunt u hier terecht.</p>
        </div>
        <Card className="homeInfoCard" style={{padding:"7px"}}>
          <h4>Aanbod Services</h4>
          <Divider/>
          <p>Het vlakken van cilinderkoppen, het slijpen van kleppen, het frezen of vervangen van de zittingen. 
            Het vervangen van de geleiders en ook het opnieuw inschuren van de kleppen. Cilinderkoppen tot één 
            meter kunnen we vlakken, zowel gietijzeren als aluminium koppen, het maakt niet uit. Ook een complete
             motorrevisie is geen enkel probleem. In onze moderne werkplaats  zijn we van alle gemakken voorzien.
              Verstuivers testen of reviseren geen probleem, hetzelfde geld voor turbo's.</p>
          <div className="readMoreButton">
          <Link to="/cilinderkop-vlakken" className="linkFix"><Button color="primary" variant="contained">Meer lezen</Button></Link>
        </div>
      </Card>
      <Card className="homeInfoCard bottom a" style={{backgroundColor:"#400101", color:"white", padding:"7px"}}>
          <h4>Aanbod Services</h4>
          <Divider/>
          <img className="homeCardIcon" src="/assets/images/icons/gear.png" alt=""/>
          <p>
            Een revisie gaat gepaard met de grootste zorg, u wilt natuurlijk dat uw motor weer als vanouds 
            draait en daarvoor zijn autentieke onderdelen nodig. daarmee worden de grootste prestaties behaalt
            en is de betrouwbaarheid een feit. In enkele gevallen zullen er meer geschiktere onderdelen beschikbaar
            zijn en zullen deze uiteraard worden benut wat de prestaties van uw motor ten goede komt.
          </p>
          <p>Wat Arjan technische service voor u kan betekenen: de motor wordt geheel overhaald en komt bij u retour 
            aan boord alsof deze als nieuw geleverd is, als u weg rijd of vaart zal de motor weer klinken en draaien 
            zoals u gewent was bij aanschaf.</p>
      </Card>
      <Card className="homeInfoCard bottom b" style={{backgroundColor:"#222222", color:"white", padding:"7px"}}>
          <h4>Aanbod Services</h4>
          <Divider/>
          <img className="homeCardIcon" src="/assets/images/icons/wrench.png" alt=""/>
          <p>
            Scheepsmotoren zijn meer aan slijtage onderheven dan automotoren, te snelle en zware belasting spelen 
            daarin een rol. Ook is de koeling vaak wat minder bij wat oudere scheepsmotoren. daarbij is het gebruik 
            van pleziervaartuigen vaak onregelmatig. Al deze factoren kunnen bijdragen tot een versnelde slijtage, 
            goed onderhoud is dan ook nodig om uw scheepsmotor in optimale conditie te houden. Het is dan ook zaak om 
            als u langer plezier van uw vaartuig wilt hebben om regelmatig onderhoud te plegen, weet u niet goed waar op 
            te letten kunnen wij u daarbij zeker helpen. Een verwaarloosde motor kan dan ook aardig wat roet in het eten 
            gooien als u op pad wilt met uw boot, niet te spreken over de eventuele kosten die daaruit kunnen 
            voortvloeien welke u zeker had kunnen besparen door de motor te (laten) onderhouden.</p>
      </Card>
      <Card className="homeInfoCard bottom c" style={{backgroundColor:"#FC5802", color:"white", padding:"7px"}}>
          <h4>Aanbod Services</h4>
          <Divider/>
          <img className="homeCardIcon" src="/assets/images/icons/truck.png" alt=""/>
          <p>
            Het vlakken van cilinderkoppen, reparatie of revisie wordt toegepast aan motoren van boten,zowel plezier 
            als beroepsvaart, vrachtwagens, tractoren en noem maar op. U kunt hier terecht met alle cilinderkoppen. 
            Niet elke garage geschikt over een cilindervlakbank, dus ook garagehouders en 
            servicebedrijven kunnen hier terecht. Arjan Technische Service beschikt over een eigen cilindervlakbank 
            in de werkplaats om uw cilinderkop nauwkeurig te vlakken. Alle reparaties aan motoren worden vakkundig 
            uitgevoerd door een ervaren monteur.
          </p>
          <p>
            Er is een ophaal- en bezorgdient aanwezig om uw onderdelen van en naar locatie te vervoeren.
          </p>
          <div className="readMoreButton">
          <Link to="/cilinderkop-vlakken/transport" className="linkFix"><Button color="primary" variant="contained">Bekijk transport opties</Button></Link>
        </div>
      </Card>
        </div>
      </Container>
    );
  }
}
