const productsArray = [
  {
    productId: "product1",
    title: "Eerste product",
    imgUrl: "/assets/images/005.jpg",
    productImages: [
      "/assets/images/005.jpg",
      "/assets/images/006.jpg",
      "/assets/images/007.jpg",
    ],
    price:"33.50",
    description:
      "Voorbeeld van een korte product omschrijving voor product 1. Niet bedoelt voor, maar ook mogelijk voor technische details",
    technicalDetails: [
      { name: "Cilinderkop Groote", value: "500 mm" },
      { name: "Cilinderkop Breedte", value: "200 mm" },
      { name: "Type Cilinderkop", value: "Die ene voor je auto enzo" },
      { name: "4de voorbeeld", value: "Nog meer text" },
    ],
  },
  {
    productId: "product2",
    title: "Tweede product",
    imgUrl: "/assets/images/006.jpg",
    productImages: [
      "/assets/images/006.jpg",
      "/assets/images/007.jpg",
      "/assets/images/005.jpg",
    ],
    price:"33.50",
    description:
      "Voorbeeld van een korte product omschrijving voor product 2. Niet bedoelt voor, maar ook mogelijk voor technische details peroiwjgpoire ijp9orejgp0 i9rejpoi9rewj gt8oip[re j[oi8g reoiu[g jhreoiu jgoi[rej go[ire oig jeroi8g reoi goi8[re goi8re goi8rehguoi reh[oi8ug hre[oiu  ghoiure hgoi u",
    technicalDetails: [
      { name: "Cilinderkop Groote", value: "500 mm" },
      { name: "Cilinderkop Breedte", value: "200 mm" },
      { name: "Type Cilinderkop", value: "Die ene voor je auto enzo" },
      { name: "4de voorbeeld", value: "Nog meer text" },
      { name: "4de voorbeeld", value: "Nog meer text" },
      { name: "4de voorbeeld", value: "Nog meer text" },
    ],
  },
  {
    productId: "product3",
    title: "Derde product",
    imgUrl: "/assets/images/007.jpg",
    productImages: [
      "/assets/images/007.jpg",
      "/assets/images/005.jpg",
      "/assets/images/006.jpg",
    ],
    price:"33.50",
    description:
      "Voorbeeld van een korte product omschrijving voor product 3. Niet bedoelt voor, maar ook mogelijk voor technische details",
    technicalDetails: [
      { name: "Cilinderkop Groote", value: "500 mm" },
      { name: "Cilinderkop Breedte", value: "200 mm" },
      { name: "Type Cilinderkop", value: "Die ene voor je auto enzo" },
      { name: "4de voorbeeld", value: "Nog meer text" },
    ],
  },
];

export default productsArray;
