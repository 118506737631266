import React from "react";

import { Link } from "react-router-dom";
import { Button, Card, Divider } from "@material-ui/core";
export default function ServicesComponent() {
  return (
    <>
      <Card
        id="services"
        style={{ backgroundColor: "#333333", color: "white", padding: "7px" }}
      >
        <h4>Aanbod Services</h4>
        <Divider />
        <ul>
          <li>Vlakken en reviseren van cilinderkoppen</li>
          <li>Revisie alle soorten motoren</li>
          <li>Service en onderhouds beurten aan jachtmotoren</li>
          <li>Levering van Eurol oliën en vetten</li>
          <li>Levering van filters</li>
          <li>Levering van accu's</li>
          <li>
            Voor cilinderkoppen een eigen haal en breng service in de regio
          </li>
        </ul>
        <div id="serviceRequestButton">
          <Link className="linkFix" to="/serviceverzoek">
            <Button variant="contained">Serviceverzoek Indienen</Button>
          </Link>
        </div>
      </Card>
    </>
  );
}
