import React from 'react';
import {TextField,Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox }  from '@material-ui/core';

import { Link } from 'react-router-dom';


export default function StateTextFields() {
  const [formData, setFormData] = React.useState({
    name:"", 
    address:"", 
    city:"", 
    zipcode:"",
    phone:"", 
    email:"",
    addressCheck:"false", 
    altAddressInfo:{
      altAddress:"",
      altCity:"", 
      altZipcode:"",
      altPhone:"", 
    },
    brand:"", 
    brandType:"", 
    cilinderinhoud:"", 
    motorcode:"", 
    checkboxes:{
      "Cilinderkop schoonmaken":false, 
      "Cilinderkop afpersen":false, 
      "Cilinderkop vlakken":false, 
      "Zittingen frezen"  :false,
      "Zittingen vervangen":false,
      "Klepgeleider vervangen":false,
      "kleppen slijpen":false,
      "Zittingen en kleppen inschuren":false,
      "Vacuum test":false,
      "Verstuivers testem":false,
      "Verstuur norzels vervangen":false,
      "Turbo revisie":false
    },
    altWerkzaamheden:"",
    validation:{
      entries:{
      name:false, 
      address:false, 
      city:false, 
      zipcode:false,
      phone:false, 
      email:false
      },
      text:""
    }
  
  });
  const [formState, setFormState] = React.useState({form:{"display":"block"}, text:{"display":"none"}})
  const handleChange = (event, input) => {
    setFormData({...formData, [input]: event.target.value});
  };
  const handleAltChange = (event, input) => {
    var currentaltAddressInfo= formData.altAddressInfo
    currentaltAddressInfo = {...currentaltAddressInfo, [input]: event.target.value}
    setFormData({ ...formData,  altAddressInfo: currentaltAddressInfo});
  };
  const handleCheckChange = (event) => {
    var currentCheckboxes = formData.checkboxes
    currentCheckboxes = {...currentCheckboxes, [event.target.name]: event.target.checked }
    setFormData({ ...formData,  checkboxes: currentCheckboxes});
  };

  const submitForm = () => {
    setFormData({...formData,  validation:{
      entries:{
      name:false, 
      address:false, 
      city:false, 
      zipcode:false,
      phone:false, 
      email:false
      },
      text:""
    }});
    var validData = true
    //Validate everything
    const updateValidation = (text) => {
      var entriesForm = formData.validation.entries
      entriesForm = {...entriesForm, [text]:true}
      var validationForm = formData.validation
      validationForm.entries = entriesForm
      setFormData({...formData, validation: validationForm});
    }
    //could be looped
    if(formData.name === ""){
      validData = false
      updateValidation("name")
    }
    if(formData.address === ""){
      validData = false
      updateValidation("address")
    }
    if(formData.city === ""){
      validData = false
      updateValidation("city")
    }
    if(formData.zipcode === ""){
      validData = false
      updateValidation("zipcode")
    }
    if(formData.phone === ""){
      validData = false
      updateValidation("phone")
    }
    if(formData.email === ""){
      validData = false
      updateValidation("email")
    }
    if(formData.brand === ""){
      validData = false
      updateValidation("brand")
    }
    if(formData.brandType === ""){
      validData = false
      updateValidation("brandType")
    }
    if(formData.cilinderinhoud === ""){
      validData = false
      updateValidation("cilinderinhoud")
    }
    if(formData.motorcode === ""){
      validData = false
      updateValidation("motorcode")
    }
    if(validData){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*"},
        body: JSON.stringify(formData)
    };
    fetch("https://emailsenderkopvlakke.herokuapp.com/send", requestOptions)
    .then(
      (result) => {
        setFormState({form:{"display":"none"}, text:{"display":"block", "textAlign":"center"}})
      },
      (error) => {
        alert(error)
      }
      )
    } else{
      formData.validation.text = "Niet alle velden zijn ingevoerd"
    }
  }

  return (
    <>
    <form className="serviceVerzoekForm" style={formState.form} noValidate autoComplete="off">

      <div>
        <TextField
          error={formData.validation.name} 
          className="textInput"
          label="(Bedrijf)snaam"
          value={formData.name}
          onChange={e => handleChange(e, "name")}
          variant="filled"
          required
        />
        <TextField
          error={formData.validation.address} 
          className="textInput"
          label="Ophaal adres en huisnummer"
          value={formData.address}
          onChange={e => handleChange(e, "address")}
          variant="filled"
          required
        />
        <TextField
          error={formData.validation.city} 
          className="textInput halfWidthInput"
          label="Woonplaats"
          value={formData.city}
          onChange={e => handleChange(e, "city")}
          variant="filled"
          required
        />
        <TextField
          error={formData.validation.zipcode} 
          className="textInput halfWidthInput"
          label="Postcode"
          value={formData.zipcode}
          onChange={e => handleChange(e, "zipcode")}
          variant="filled"
          required
        />
        <TextField
          error={formData.validation.phone} 
          className="textInput halfWidthInput"
          type="number"
          label="Telefoonnummer contactpersoon"
          value={formData.phone}
          onChange={e => handleChange(e, "phone")}
          variant="filled"
          required
        />
        <TextField
          error={formData.validation.email} 
          className="textInput halfWidthInput"
          id="filled-name"
          label="E-mail address"
          value={formData.email}
          onChange={e => handleChange(e, "email")}
          variant="filled"
          required
        />
        <FormControl className="formTitle" component="fieldset">
          <FormLabel component="legend" >Is het factuuradres anders dan het ophaaladres?</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={formData.addressCheck} onChange={e => handleChange(e, "addressCheck")} required>
            <FormControlLabel value="true" control={<Radio />} label="Ja" />
            <FormControlLabel value="false" control={<Radio />} label="Nee" />
          </RadioGroup>
        </FormControl>
        <div id="alternativeaddress" style={{"display" : formData.addressCheck !== "true"? "none" : "block"}}>
        <FormLabel component="legend" className="formTitle"><b>Voer uw Factuur address in</b></FormLabel>
        <TextField
          className="textInput halfWidthInput"
          id="filled-name"
          label="Factuur address"
          value={formData.altAddressInfo.altAddress}
          onChange={e => handleAltChange(e, "altAddress")}
          variant="filled"
          
        />
        <TextField
          className="textInput halfWidthInput"
          // id="filled-name"
          label="Woonplaats"
          value={formData.altAddressInfo.altCity}
          onChange={e => handleAltChange(e, "altCity")}
          variant="filled"
        />
        <TextField
          className="textInput halfWidthInput"
          id="filled-name"
          label="Postcode"
          value={formData.altAddressInfo.altZipcode}
          onChange={e => handleAltChange(e, "altZipcode")}
          variant="filled"
        />
        <TextField
          className="textInput halfWidthInput"
          type="number"
          id="filled-name"
          label="Telefoonnummer contactpersoon"
          value={formData.altAddressInfo.altPhone}
          onChange={e => handleAltChange(e, "altPhone")}
          variant="filled"
        />
        </div>
        {/* <b>Motor gegevens</b> */}
        <FormLabel component="legend" className="formTitle"><b>Motor gegevens</b></FormLabel>

        <TextField
          className="textInput"
          // id="filled-name"
          label="Merk"
          value={formData.brand}
          onChange={e => handleChange(e, "brand")}
          variant="filled"
          required
        />
        <TextField
          className="textInput"
          id="filled-name"
          label="Type"
          value={formData.brandType}
          onChange={e => handleChange(e, "brandType")}
          variant="filled"
          required
        />
        <TextField
        className="textInput"
          id="filled-name"
          label="CilinderInhoud"
          value={formData.cilinderinhoud}
          onChange={e => handleChange(e, "cilinderinhoud")}
          variant="filled"
          required
        />
        <TextField
        className="textInput"
          id="filled-name"
          label="Motorcode"
          value={formData.motorcode}
          onChange={e => handleChange(e, "motorcode")}
          variant="filled"
          required
        />
        <FormControl component="fieldset" className="formTitle">
        <FormLabel component="legend" ><b>Werkzaamheden</b></FormLabel>
        <FormGroup>
        {Object.keys(formData.checkboxes).map((text, index) => (
          <FormControlLabel
          control={<Checkbox checked={formData.checkboxes[text]} onChange={handleCheckChange} name={text} />}
          label={text}
          />
          ))}
        </FormGroup> 
        </FormControl>
        <TextField
        className="textInput"
          id="filled-name"
          label="Andere werkzaamheden"
          value={formData.altWerkzaamheden}
          onChange={e => handleChange(e, "altWerkzaamheden")}
          variant="filled"
          multiline
          rows={4}
        />
      </div>
      <p style={{color:"red"}}><b>{formData.validation.text} </b></p>
          <Button onClick={submitForm} variant="contained">Verstuur serviceverzoek</Button>
    </form>
    <h2 style={formState.text}>Uw verzoek is verstuurd!</h2>
    <Link className="linkFix" to="/"><Button style={formState.text} variant="contained">Terug naar Home</Button></Link>
    </>
  );
}