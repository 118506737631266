import React from "react";
import {
  Divider,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Fab,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import { Link } from "react-router-dom";
export default function HeadBanner() {
  const [state, setState] = React.useState({ openMenu: false });
  const navItems = [
    {
      name: "Arjan Technische Service",
      link: "/",
    },
    {
      name: "Cilinderkop Vlakken",
      link: "/cilinderkop-vlakken",
    },
    {
      name: "Transport",
      link: "/cilinderkop-vlakken/transport",
    },
    {
      name: "Jachtonderhoud",
      link: "/jachtonderhoud",
    },
    {
      name: "Contact",
      link: "/contact",
    },
    // {
    //   name: "Webshop",
    //   link: "/webshop",
    // },
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, openMenu: open });
  };
  // Mobile navigation list
  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div id="mobileNavContactButton">
        {/* className="mobileNavContactButton" */}
        <a href="mailto:info@kopvlakke.nl">
          <Fab size="small" color="primary" aria-label="add">
            <MailIcon />
          </Fab>
        </a>
        <a href="tel:+31 6 53 50 82 79">
          <Fab size="small" color="primary" aria-label="add">
            <PhoneIcon />
          </Fab>
        </a>
      </div>
      <Divider />
      <List>
        {navItems.map((text, index) => (
          <Link className="linkFix" to={text.link}>
            <ListItem button key={text.name}>
              <ListItemText primary={text.name} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
  return (
    <div id="banner">
      <div id="fixedBanner">
        <IconButton
          edge="start"
          className="menuIcon"
          onClick={toggleDrawer(true)}
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Link className="linkFix" to="/">
          <img src="/assets/images/ATSLogo.png" alt="foto van logo" />
        </Link>
        <div id="desktopText">
          <h3>Voor al uw reparaties en technisch onderhoud</h3>
          <h4>+31 (0)6 5350 8279</h4>
          <h4>info@kopvlakke.nl</h4>
        </div>
        <Divider id="bannerDivider" />
      </div>
      <h4 className="mobileText">
        Voor al uw reparaties en technisch onderhoud
      </h4>
      <div className="mobileContactInfo">
        <a className="linkFix" href="mailto:info@kopvlakke.nl">
          <span className="mobileContactInfo">
            <Button variant="contained" color="primary">
              <MailIcon />
              <h5 className="mobileText">info@kopvlakke.nl</h5>
            </Button>
          </span>
        </a>
        <a className="linkFix" href="tel:+31 6 53 50 82 79">
          <span className="mobileContactInfo">
            <Button variant="contained" color="primary">
              <PhoneIcon />
              <h5 className="mobileText">+31 (0)6 53508279</h5>
            </Button>
          </span>
        </a>
      </div>
      {/* Desktop Navigation */}
      <Paper id="desktopNav" position="static" color="default">
        <div id="desktopNavButtons">
          {navItems.map((text, index) => (
            <Link key={index} className="linkFix" to={text.link}>
              <Button className="desktopNavButton">{text.name}</Button>
            </Link>
          ))}
        </div>
      </Paper>
      {/* drawer initially hidden */}
      <Drawer
        anchor={"left"}
        open={state.openMenu}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}
