import React from "react";
import { Container} from "@material-ui/core";
import ServiceVerzoekComponent from "../../components/ServiceVerzoekComponent"
export default class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container id="serviceVerzoek">
        <h1>Nieuw service verzoek!</h1>
        <p>Voer de informatie hier onder in om een service verzoek in te dienen</p>
          <ServiceVerzoekComponent/>
      </Container>
    );
  }
}
