import React from "react";
import {
  TextField,
  Button,
  ButtonGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import { Link } from "react-router-dom";

export default function StateTextFields({prodInfo}) {
  const [formData, setFormData] = React.useState({
    name: "",
    address: "",
    city: "",
    zipcode: "",
    phone: "",
    email: "",
    pickup: "true",
    personalMessage: "",
    prodName: prodInfo.title,
    // url: this.props.location.pathname,
    prodUrl: "urlpath",
    prodPrice: prodInfo.price,
    validation: {
      entries: {
        name: false,
        address: false,
        city: false,
        zipcode: false,
        phone: false,
        email: false,
      },
      text: "",
    },
  });
  const [formState, setFormState] = React.useState({
    form: { display: "block" },
    text: { display: "none" },
  });
  const handleChange = (value, input) => {
    console.log(value);
    setFormData({ ...formData, [input]: value });
    console.log(formData.pickup);
  };

  const submitForm = () => {
    setFormData({
      ...formData,
      validation: {
        entries: {
          name: false,
          address: false,
          city: false,
          zipcode: false,
          phone: false,
          email: false,
        },
        text: "",
      },
    });
    var validData = true;
    //Validate everything
    const updateValidation = (text) => {
      var entriesForm = formData.validation.entries;
      entriesForm = { ...entriesForm, [text]: true };
      var validationForm = formData.validation;
      validationForm.entries = entriesForm;
      setFormData({ ...formData, validation: validationForm });
    };
    //could be looped
    if (formData.name === "") {
      validData = false;
      updateValidation("name");
    }
    if (formData.address === "") {
      validData = false;
      updateValidation("address");
    }
    if (formData.city === "") {
      validData = false;
      updateValidation("city");
    }
    if (formData.zipcode === "") {
      validData = false;
      updateValidation("zipcode");
    }
    if (formData.phone === "") {
      validData = false;
      updateValidation("phone");
    }
    if (formData.email === "") {
      validData = false;
      updateValidation("email");
    }
    if (formData.brand === "") {
      validData = false;
      updateValidation("brand");
    }
    if (validData) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(formData),
      };
      fetch("http://localhost:4242/productRequest", requestOptions).then(
        (result) => {
          setFormState({
            form: { display: "none" },
            text: { display: "block", textAlign: "center" },
          });
        },
        (error) => {
          alert(error);
        }
      );
    } else {
      formData.validation.text = "Niet alle velden zijn ingevoerd";
    }
  };

  return (
    <>
      <form
        className="serviceVerzoekForm"
        style={formState.form}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            error={formData.validation.name}
            className="textInput"
            label="(Bedrijf's)naam"
            value={formData.name}
            onChange={(e) => handleChange(e.target.value, "name")}
            variant="filled"
            required
          />
          <TextField
            error={formData.validation.address}
            className="textInput"
            label="Ophaal adres en huisnummer"
            value={formData.address}
            onChange={(e) => handleChange(e.target.value, "address")}
            variant="filled"
            required
          />
          <TextField
            error={formData.validation.city}
            className="textInput"
            label="Woonplaats"
            value={formData.city}
            onChange={(e) => handleChange(e.target.value, "city")}
            variant="filled"
            required
          />
          <TextField
            error={formData.validation.zipcode}
            className="textInput"
            label="Postcode"
            value={formData.zipcode}
            onChange={(e) => handleChange(e.target.value, "zipcode")}
            variant="filled"
            required
          />
          <br />
          <p>Wilt u het product afhalen, of bezorgen?</p>
          <ButtonGroup disableElevation className="textInput" color="primary">
            <Button
              className="pickupButtons"
              onClick={() => handleChange("true", "pickup")}
              variant={formData.pickup === "true" ? "contained" : ""}
            >
              Afhalen
            </Button>
            <Button
              className="pickupButtons"
              onClick={() => handleChange("false", "pickup")}
              variant={formData.pickup === "false" ? "contained" : ""}
            >
              Bezorgen
            </Button>
          </ButtonGroup>
          <br />
          <TextField
            error={formData.validation.phone}
            className="textInput"
            type="number"
            label="Telefoonnummer contactpersoon"
            value={formData.phone}
            onChange={(e) => handleChange(e.target.value, "phone")}
            variant="filled"
            required
          />
          <TextField
            error={formData.validation.email}
            className="textInput"
            id="filled-name"
            label="E-mail address"
            value={formData.email}
            onChange={(e) => handleChange(e.target.value, "email")}
            variant="filled"
            required
          />
          <TextField
            className="textInput"
            id="filled-name"
            label="Persoonlijk Bericht"
            value={formData.personalMessage}
            onChange={(e) => handleChange(e.target.value, "personalMessage")}
            variant="filled"
            multiline
            rows={4}
          />
        </div>
        <p style={{ color: "red" }}>
          <b>{formData.validation.text} </b>
        </p>
        <Button onClick={submitForm} variant="contained">
          Verstuur serviceverzoek
        </Button>
      </form>
      <h2 style={formState.text}>Uw verzoek is verstuurd!</h2>
      <Link className="linkFix" to="/">
        <Button style={formState.text} variant="contained">
          Terug naar de homepagina
        </Button>
      </Link>
      <br />
      <Link className="linkFix" to="/webshop">
        <Button style={formState.text} variant="contained">
          Terug naar de Webshop
        </Button>
      </Link>
    </>
  );
}
