import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect  } from "react-router-dom";
// Pages
import Home from "./pages/Home/Home";
import CilinderkopVlakken from "./pages/cilinderkopvlakken/CilinderkopVlakken";
import CilinderkopVlakkenTransport from "./pages/cilinderkopvlakkentransport/CilinderkopVlakkenTransport";
import JachtOnderhoud from "./pages/jachtonderhoud/JachtOnderhoud";
import Contact from "./pages/contact/Contact";
import ServiceVerzoek from "./pages/serviceVerzoek/serviceVerzoek"
import Webshop from "./pages/Webshop/Webshop"
import Product from "./pages/Webshop/Product"
import ProductVerzoek from "./pages/Webshop/ProductVerzoek"
//Components
import HeadBanner from "./components/HeadBanner.jsx";
export default class Routes extends React.Component {
  
  render() {

    return (
      <div>
        <Router>
        <HeadBanner />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/cilinderkop-vlakken" component={CilinderkopVlakken} />
              <Route exact path="/cilinderkop-vlakken/transport" component={CilinderkopVlakkenTransport} />
              <Route exact path="/jachtonderhoud" component={JachtOnderhoud} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/serviceverzoek" component={ServiceVerzoek} />
              {/* <Route exact path="/webshop" component={Webshop} />
              <Route exact path="/webshop/:id" component={Product} /> */}
              {/* <Route exact path="/productverzoek/:id" component={ProductVerzoek} /> */}
              <Redirect from='*' to='/' />
            </Switch>
        </Router>
      </div>
    );
  }
}
