import React from "react";
// Components
import { Container } from "@material-ui/core";

export default class CilinderkopVlakken extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container id="cilVlak">
        <div id="cilVlakContent">
        <img id="cilVlakBanner" src="/assets/images/turborevisie.jpg" alt="turbo revisie"/>
        <div id="homeContent">
        <h2 >Vlakken van cilinderkoppen</h2>
        <div id="homeTextblock">
          <img id="cilKopVlakken" src="/assets/images/cilinderkop_vlakken.jpg" alt="cilinder kop vlakken"/>
          <h3>Cilinderkop</h3>
          <p>
            De cilinderkop is onderdeel van een verbrandingsmotor. Hij zit boven op het motorblok en sluit de cilinders af.
            De koppakking bevindt zich tussen de cilinderkop en het motorblok en zorgt voor een hermetische afdichting tussen beide.
            Bij een kopklepmotor maken de uitsparingen in de cilinderkop onderdeel uit van de verbrandingskamers.
            Bij viertaktmotoren van dit type bevat de kop ook de kleppen en het mechanisme om deze op het juiste moment te openen en 
            te sluiten. Vandaag de dag zijn zulke motoren daartoe uitgerust met een zogenaamde 'bovenliggende' nokkenas, die zich eveneens in de kop bevindt. 
            Bij de benzinemotor heeft de cilinderkop openingen waarin de bougies worden geschroefd.
          </p>
          <h3>Wanneer vlakken van de cilinderkop</h3>
          <p>
          Wanneer een motor oververhit is geweest is het soms niet meer mogelijk deze weer goed te krijgen door het plaatsen van een 
          nieuwe koppakking. Omdat de cilinderkop niet meer recht is zal door de ongelijkheid de pakking niet meer 100 % 
          afdichten.<br/>
          We zullen de cilinderkop moeten gaan vlakken.
          </p>
          <h3>Vlakken</h3>
          <p>
            Het weer recht maken van de cilinderkop gebeurt op een vlakbank heet vlakken, dit gebeurt met een 
            speciaal vlak gemaakte steen, er kan met het het inspannen van de cilinderkop op de vlakbank een 
            hoge nauwkeurigheid worden verkregen van 0.02mm. Dit is een dikte die altijd verloren is. Er zal ook 
            nooit meer worden weggeslepen dan wat noodzakelijk is. <br/>Normaal wanneer de schade beperkt is gebleven 
            met betrekking op de kromming zal één pakking voldoen, indien een kop al eerder gevlakt is, kan het zijn 
            dat er een dikkere pakking of bij uitzondering 2 pakkingen gebruikt moeten worden. Arjan Technische Service 
            zal u in beide gevallen inlichten en u de beste oplossing toelichten.
          </p>
        </div>
        </div>
        </div>
      </Container>
    );
  }
}
