import React from "react";
import { Container, Card, Divider } from "@material-ui/core";
const cardTitleStyle ={backgroundColor:"#333333", color:"white", padding:"4px"}
export default class JachtOnderhoud extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container id="jachtOnderhoud">
        <div id="cilVlakContent">
          <h1>Wat Arjan Technische Service u kan bieden om uw jacht in optimale conditie te houden</h1>
          <div id="jachtOnderhoudServiceCards">
            <Card variant="outlined" className="jachtOnderhoudServiceCard">
              <Card style={cardTitleStyle}><b>Scheepsmotor winterklaar maken</b></Card>
              <p>Watergekoelde uitlaat: Water met antivries er vanaf de wierpot doorheen pompen, daarna impeller demonteren. indien aanwezig: waterzak aftappen en gasoliefilters controleren op bacteriën. e.v.t. smeerolie en filter vervangen, kleppen stellen.</p>
            </Card>
            <Card variant="outlined" className="jachtOnderhoudServiceCard">
              <Card style={cardTitleStyle}><b>Accu's winterklaar maken</b></Card>
              <p>Zorg dat deze volgeladen zijn om beter tegen lagere temperaturen te beschermen.</p>
            </Card>
            <Card variant="outlined" className="jachtOnderhoudServiceCard">
              <Card style={cardTitleStyle}><b>Buitenboordmotor winterklaar maken</b></Card>
              <p>Olie verversen (4-takt), bourgie('s) vervangen, impeller vervangen. carburateur reinigen. proefdraaien en afstellen. Carburateur leegdraaien of aftappen.</p>
            </Card>
            <Card variant="outlined" className="jachtOnderhoudServiceCard">
              <Card style={cardTitleStyle}><b>Gesloten koelsysteem winterklaar maken</b></Card>
              <p>Koelvloeistof verversen. de corrosiewerende middelen werken circa 2 jaar.</p>
            </Card>
            </div>
            <Card variant="outlined" id="bottomCard" className="jachtOnderhoudServiceCard">
              <Card style={cardTitleStyle}><b>Eurol oliechecker</b></Card>
              {/* <img src="/assets/images/eurol.jpg"/> */}
              <iframe src="https://eurol.com/olie-adviseur/?iframe=autobedrijf-bouwman" width="100%" title="W3Schools Free Online Web Tutorials"></iframe>

            </Card>
            <Divider style={{margin:"5px"}}/>
          <Card id="dealerCard">
            <h3 style={{backgroundColor:"#222222", color:"white", padding:"7px"}}>Official dealer van:</h3>
            <Divider/>
            <div id="dealerImages">
              <a href="http://www.cristec.nl/" rel="noreferrer noopener" target="_blank"><img src="/assets/images/partners/cristec.jpg" alt="cristec"/></a>
              <a href="http://www.eurol.com/nl/" rel="noreferrer noopener" target="_blank"><img src="/assets/images/partners/eurol_logo.jpg" alt="eurol"/></a>
              <a href="http://www.optima-batterien.eu/nl/productinformatie.html" rel="noreferrer noopener" target="_blank"><img src="/assets/images/partners/Optima.jpg" alt="optima"/></a>
              <a href="http://www.fullriverdcbattery.com/" rel="noreferrer noopener" target="_blank"><img src="/assets/images/partners/Fullriver.jpg" alt="fullriver"/></a>
            </div>
          </Card>
        </div>
      </Container>
    );
  }
}
