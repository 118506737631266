import React from "react";
import {
  Container,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Button,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import productsArray from "../../components/ProductInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Link } from "react-router-dom";

export default class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      productInfo: productsArray.find(
        (x) => x.productId === props.match.params.id
      ),
    };
  }
  getProductImages() {
    if (this.state.productInfo.productImages.length > 1) {
      SwiperCore.use([Autoplay]);
      return (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 2000 }}
          effect="fade"
          loop="true"
          id="swiperComponent"
        >
          {this.state.productInfo.productImages.map((productImageLink) => {
            return (
              <SwiperSlide className="swiperSlide">
                <img src={productImageLink} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      );
    } else if (this.state.productInfo.productImages.length === 1) {
      return <img src={this.state.productInfo.productImages[0]} alt="" />;
    } else {
      return;
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if (!this.state.productInfo) {
      return <Redirect to="/webshop" />;
    }
    return (
      <Container id="product">
        <h1 id="productTitle">{this.state.productInfo.title}</h1>
        {this.getProductImages()}
        <h3 id="productDescriptionTitle">Beschrijving:</h3>
        <p id="productDescription">{this.state.productInfo.description}</p>
        <h3 id="productTechDetailsTitle">Technische details:</h3>
        <TableContainer id="productTechDetails" component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {this.state.productInfo.technicalDetails.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Link to={"/productverzoek/"+this.state.productInfo.productId}><Button id="productContactButton" variant="contained">
          Neem Contact op
        </Button></Link>
      </Container>
    );
  }
}
