import React from "react";
import { Container, Card, Button } from "@material-ui/core";
import  productsArray from '../../components/ProductInfo'

import { Link } from "react-router-dom";
export default class Webshop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
        <Container id="webshop">
            <h1 id="webshopTitle">Arjan's Technische Service Webshop</h1>
            <h2 id="webshopDescription">Hier vind u onze producten</h2>
            <div id="mainBody">
                {productsArray.map(object => {
                    return(
                        <Card key={object.productId} className="webProduct">
                            <h2 className="webProductTitle">{object.title}</h2>
                            <img className="webProductImage" src={object.imgUrl} alt=""></img>
                            <p className="webProductDescription">
                                {object.description}
                            </p>
                            <Link className="linkFix" to={"webshop/"+object.productId}><Button className="webProductButton">Bekijk meer</Button></Link>
                        </Card>
                    )
                })}
            </div>
        </Container>
    );
  }
}
